import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import { useSelector } from 'react-redux'
import Card from "../Common/Cards/index"
import AddIcon from '@mui/icons-material/Add';
import { GiCard10Spades } from "react-icons/gi";
import { FaEye } from "react-icons/fa";

const Player = ({ player, winner, totalPlayers, index, gameResult, handleShowCards }) => {
    const userData = useSelector(state => state.auth.userData)
    const getCardsClassName = (index) => {
        // if (totalPlayers === 2) {
        //     if (index === 0) return 'absolute -top-16 left-1/2 transform -translate-x-1/2'
        //     if (index === 1) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
        // }
        // if (totalPlayers === 3) {
        //     if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
        //     if (index === 1) return 'absolute left-16 top-0 rotate-90'
        //     if (index === 2) return 'absolute right-16 rotate-90 top-0'
        // }
        // if (totalPlayers === 4) {
        //     if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
        //     if (index === 1) return 'absolute left-16 top-0 rotate-90'
        //     if (index === 2) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
        //     if (index === 3) return 'absolute right-16 rotate-90 top-0'
        // }

        // if (totalPlayers === 5) {
        //     if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
        //     if (index === 1) return 'absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4'
        //     if (index === 2) return 'absolute  top-1/2 left-1/2  translate-y-1/2 translate-x-1/4 '
        //     if (index === 3) return 'absolute  top-1/2 right-1/2  translate-y-1/2 -translate-x-1/4 '
        //     if (index === 4) return 'absolute bottom-1/2 right-1/2 -translate-y-1/4 -translate-x-1/4'
        // }

        // if (totalPlayers === 6) {
        //     if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
        //     if (index === 1) return 'absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4'
        //     if (index === 2) return 'absolute top-1/2 left-1/2  translate-y-1/2 translate-x-1/4 '
        //     if (index === 3) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
        //     if (index === 4) return 'absolute  top-1/2 right-1/2  translate-y-1/2 -translate-x-1/4 '
        //     if (index === 5) return 'absolute bottom-1/2 right-1/2 -translate-y-1/4 -translate-x-1/4'
        // }
        // if (totalPlayers === 7) {
        //     if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
        //     if (index === 1) return 'absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4'
        //     if (index === 2) return 'absolute left-16 top-1/2 rotate-90'
        //     if (index === 3) return 'absolute top-1/2 left-1/2  translate-y-1/2 translate-x-1/4 '
        //     if (index === 4) return 'absolute  top-1/2 right-1/2  translate-y-1/2 -translate-x-1/4 '
        //     if (index === 5) return 'absolute right-16 top-1/2 rotate-90'
        //     if (index === 6) return 'absolute bottom-1/2 right-1/2 -translate-y-1/4 -translate-x-1/4'
        // }
        // if (totalPlayers === 8) {
        //     if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
        //     if (index === 1) return 'absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4'
        //     if (index === 2) return 'absolute left-16 top-1/2 rotate-90'
        //     if (index === 3) return 'absolute top-1/2 left-1/2  translate-y-1/2 translate-x-1/4 '
        //     if (index === 4) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
        //     if (index === 5) return 'absolute  top-1/2 right-1/2  translate-y-1/2 -translate-x-1/4 '
        //     if (index === 6) return 'absolute right-16 top-1/2 rotate-90'
        //     if (index === 7) return 'absolute bottom-1/2 right-1/2 -translate-y-1/4 -translate-x-1/4'
        // }
        if (totalPlayers === 9) {
            if (index === 0) return 'absolute -top-14 left-1/2 transform -translate-x-1/2'
            if (index === 1) return 'absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4'
            if (index === 2) return 'absolute left-16 top-1/2 rotate-90'
            if (index === 3) return 'absolute top-1/2 left-1/2  translate-y-1/2 translate-x-1/4 '
            if (index === 4) return 'absolute top-20 left-1/2 transform -translate-x-1/2'
            if (index === 5) return 'absolute  top-1/2 right-1/2  translate-y-1/2 -translate-x-1/4 '
            if (index === 6) return 'absolute right-16 top-1/2 rotate-90'
            if (index === 7) return 'absolute bottom-1/2 right-1/2 -translate-y-1/4 -translate-x-1/4'
        }



    }

    const highLightWinner = (userId) => {
        if (!winner) return false
        if (winner && winner.length === 0) return false
        const winnersUserId = winner?.map(winner => winner.userId)
        return winnersUserId.includes(userId)
    }

    const winnerCardHand = () => {
        if (player.isFolded) return false
        if (player.isLeft) return false
        if (gameResult['isAllIn']) {

            const { winningHands } = gameResult
            if (!winningHands) return false

            const winnerPlayer = winningHands.find(winner => winner.userId === player.userId)

            const winningHandsIndex = winningHands.findIndex(winner => winner.userId === player.userId)
            if (winningHandsIndex === -1) return false

            if (!winnerPlayer) return false
            if (player.type == 'deposit') {
                return <span className='capitalize'>{winnerPlayer['rank']}  {winnerPlayer?.hand}</span>
            }
            return <span className='capitalize'>{winnerPlayer['rank']}  {winnerPlayer?.hand}</span>
        }

        const { winningHands } = gameResult
        if (!winningHands) return false


        const winnerPlayer = winningHands.find(winner => winner.userId === player.userId)
        if (!winnerPlayer) return false
        const winningHandsIndex = winningHands.findIndex(winner => winner.userId === player.userId)
        if (winningHandsIndex === -1) return false


        if (player.type == 'deposit') {
            return <span className='capitalize'>{winnerPlayer['rank']}  {winnerPlayer?.hand}</span>
        }

        return <span className='capitalize'>{winnerPlayer['rank']}  {winnerPlayer?.hand}</span>


    }

    const Cards = () => {

        const allCards = player.cards || []
        return allCards.map((card, index) => (
            <div className=''>
                {/* <GiCard10Spades fontSize={50} fill='white'/> */}

                {/* <Card textSize={'text-xs'} fontSize={12} className={`z-20 h-16 w-10 rounded-sm ${highLightWinner(player.userId) ? "border-2 border-purple-400 shadow-purple-200 shadow-2xl rounded-md" : ""} rounded-md`} key={index} card={card} index={index} /> */}
            </div>
        ))
    }

    const RenderPlayerAmount = ({ player }) => {
        let totalAmount = player['totalAmount']
        totalAmount = Number(totalAmount).toFixed(3)
        let type = player['type']

        if (type === 'deposit') {
            let playerAmount = player['amount']
            playerAmount = Number(playerAmount).toFixed(3)

            let playerRestAmount = player['restAmount']
            playerRestAmount = Number(playerRestAmount).toFixed(3)

            let newTotalAmount = playerRestAmount - playerAmount
            newTotalAmount = Number(newTotalAmount).toFixed(3)

            newTotalAmount = Math.abs(newTotalAmount).toFixed(3)


            let playerTotalAmount = Number(newTotalAmount) + Number(totalAmount)
            playerTotalAmount = Number(playerTotalAmount).toFixed(3)

            if (player.userId === userData?.id) {
                return <span className='text-xs text-green-400 font-extrabold'> + {playerTotalAmount} Sol</span>
            }
            return <span className='text-xs text-green-400 font-extrabold'> + {totalAmount} Sol</span>
        }
        if (type === 'withdrawal') {
            return <span className='text-xs text-red-500 font-extrabold'>- {totalAmount} Sol</span>
        }
    }



    if (player.fake) {
        return (<div className={`cursor-pointer hover:scale-105 p-2 flex flex-col items-center text-white font-extrabold bg-red-600 rounded-full border-2 border-red-600`}>
            <AddIcon fontSize='small' className='font-extrabold' />
        </div>)
    }


    return (
        <div className='relative hi-lo-player flex items-center flex-col	'>
            {!player.isFolded && !player.isLeft && <div className={` flex items-center justify-center gap-0 ${getCardsClassName(index)}`}>
                {/* <Cards /> */}
                {/* <span className='cursor-pointer'>View Cards</span> */}
            </div>}
            {player.isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-44  p-1/2 ${winner.userId == player.userId && ""}`} />}
            {player.isLeft && !player.isFolded && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile  h-16 w-44  p-1/2 ${winner.userId == player.userId && ""}`} />}


            {<img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-10 w-10 border border-white p-1/2 ${player.active && "active"}`} />}
            <div className='flex items-center flex-col font-bold text-xs justify-center bg-white px-3 text-center border-2 rounded-md'>
                {player.userName ? player.userName : formatAddress(player.address, 3)} {" "} 
                <span>(<RenderPlayerAmount player={player} />)</span>
            </div>
            {!player.isFolded && !player.isLeft && <span onClick={() => handleShowCards(player.cards)} className='cursor-pointer  text-sm text-white font-bold -bottom-0 bg-purple-600  py-0.5 px-2 w-max text-center flex items-center justify-between'>
                {winnerCardHand()} <span className='text-xs inline ml-2'><FaEye className='inline' />
                </span>
            </span>}
        </div>
    )
}

export default Player
