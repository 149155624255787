import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../../../Config/axios';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FireSvg from "../../../../../../assets/img/games/fire.svg";
import hotToast from 'react-hot-toast';

const BetButtons = ({ gameBetStatus }) => {
    const userData = useSelector(state => state.auth.userData)
    const { gameAddress } = useParams()
    const [raiseAmount, setRaiseAmount] = useState(0.05)
    const [isSelected, setIsSelected] = useState(false)

    useEffect(() => {
        if (gameBetStatus?.userId === userData.id) {
            setIsSelected(false)
        }
    }, [gameBetStatus])


    const myBalance = gameBetStatus?.userBalances.find(player => player?.userId == userData.id)?.amount || 0

    // Function to handle the call action
    const handleCall = async () => {
        
        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/highlow/play-bet', { gameAddress, action: 'Call', amount: gameBetStatus.betAmount })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 2000, id: 'hilo-error' })
            } 
        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 2000, id: 'hilo-error' })
        }
    }

    // Function to handle the fold action
    const handleFold = async () => {

        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/highlow/play-bet', { gameAddress, action: 'Fold', amount: 0 })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 2000, id: 'hilo-error' })
            }
        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 2000, id: 'hilo-error' })
        }
    }

    // Function to handle the raise action
    const handleRaise = async () => {

        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/highlow/play-bet', { gameAddress, action: 'Raise', amount: Number(formik.values.raisedAmount).toFixed(3) })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 2000, id: 'hilo-error' })
            }

        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 2000, id: 'hilo-error' })
        }
    }

    // Function to handle the check action
    const handleCheck = async () => {

        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/highlow/play-bet', { gameAddress, action: 'Check', amount: 0 })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 2000, id: 'hilo-error' })
            }

        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 2000, id: 'hilo-error' })
        }
    }

    // Function to handle the bet action
    const handleBet = async () => {

        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/highlow/play-bet', { gameAddress, action: 'Bet', amount: 0.05 })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 2000, id: 'hilo-error' })
            }

        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 2000, id: 'hilo-error' })
        }
    }


    // Function to handle the allin action
    const handleAllIn = async () => {

        try {
            setIsSelected(true)
            const response = await axiosInstance.post('/api/game/highlow/play-bet', { gameAddress, action: 'allin', amount: 0.05 })

            const { data: responseData } = response;

            if (!responseData.status) {
                setIsSelected(false)
                hotToast.error(responseData.message, { duration: 2000, id: 'hilo-error' })
            }

        } catch (error) {
            setIsSelected(false)
            hotToast.error(error.message, { duration: 2000, id: 'hilo-error' })
        }
    }



    const handleAction = (action) => {
        if (action === 'Call') handleCall();
        if (action === 'Fold') handleFold();
        if (action === 'Raise') handleRaise();
        if (action === 'Check') handleCheck();
        if (action === 'Bet') handleBet();
        if (action === 'allin') handleAllIn();
        return;
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            raisedAmount: (Number(Number(raiseAmount).toFixed(3)) + Number(Number(gameBetStatus?.maxBet).toFixed(3))).toFixed(3)
        },
        validationSchema: Yup.object({
            raisedAmount: Yup.number().required('Required').min((Number(Number(gameBetStatus?.maxBet).toFixed(3)) + 0.05), `Amount must be greater than ${(Number(Number(gameBetStatus?.maxBet).toFixed(3)) + 0.05).toFixed(3)}`).max(myBalance, 'Insufficient balance')
        }),
        onSubmit: () => {
            handleAction('Raise')
        }
    })


    const buttons = gameBetStatus.buttons;

    const handleIncreaseAmount = () => {
        if (raiseAmount >= myBalance) return
        setRaiseAmount(raiseAmount + 0.05)
    }

    const handleDecreseAmount = () => {
        if (Number(raiseAmount).toFixed(3) <= 0.05) return
        setRaiseAmount(raiseAmount - 0.05)
    }

    let showAllIn = false

    let newMaxBetAmount = Number(gameBetStatus.maxBet).toFixed(3)
    newMaxBetAmount = Number(newMaxBetAmount)

    let newMyBalance = Number(myBalance).toFixed(3)
    newMyBalance = Number(newMyBalance)

    if (newMyBalance > newMaxBetAmount) {
        showAllIn = true
    }


    if (isSelected) return null

    return (
        <>
            <div className="flex flex-col justify-center space-x-4 p-1 w-full">
                <div className='call_action p-0 text-xs flex flex-row items-start justify-center rounded-lg gap-1'>
                    {buttons.includes('Call') && <button onClick={() => handleAction('Call')} className="bg-blue-900  text-xs w-full py-2.5 px-3 rounded-lg mb-1 text-white inset_shadow">
                        Call <span >({Number(gameBetStatus.betAmount).toFixed(3)} Sol)</span>
                    </button>}
                    {buttons.includes('Check') && <button onClick={() => handleAction('Check')} className="bg-blue-800 text-xs w-full py-2.5 px-3 rounded-lg mb-1 text-white inset_shadow">
                        Check
                    </button>}
                    {buttons.includes('Fold') && <button onClick={() => handleAction('Fold')} className="bg-red-800 text-xs py-2.5 rounded-lg text-white w-full inset_shadow">
                        Fold
                    </button>}
                    {buttons.includes('Bet') && <button onClick={() => handleAction('Bet')} className="bg-blue-300 text-xs py-2.5 rounded-lg text-black w-full inset_shadow">
                        Bet (0.05 Sol)
                    </button>}
                    {showAllIn && buttons.includes('AllIn') && <button onClick={() => handleAction('allin')} className="bg-transparent py-2 text-red-400 text-xs rounded-lg font-medium border-2 border-red-400 w-full flex items-center justify-center">
                        All-In <img src={FireSvg} className='w-4 h-4 ml-1' />
                    </button>}
                </div>

                {buttons.includes('Raise') && <form onSubmit={formik.handleSubmit} className='raise_action mx-0 mt-2 gap-1 flex flex-row text-xs items-center justify-between rounded-lg w-full' >
                    <div className='flex flex-col w-36'>
                        <input placeholder='Example: 0.30' type="number" name='raisedAmount' value={formik.values.raisedAmount} onChange={formik.handleChange} className=" py-2.5 px-3 rounded-lg  text-white bg-gray-600 border border-white" />
                    </div>

                    <button type='submit' className="bg-green-800 border py-2.5 rounded-lg text-white w-full">
                        Raise {Number(formik.values.raisedAmount).toFixed(3)} Sol
                    </button>
                    <button type='button' onClick={handleIncreaseAmount} className="text-white text-xs h-full py-1.5 px-2 border bg-green-800 rounded-lg">
                        <AddIcon />
                    </button>
                    <button type='button' onClick={handleDecreseAmount} className="text-white text-xs h-full py-1.5 px-2 border bg-green-800 rounded-lg">
                        <RemoveIcon />
                    </button>
                </form>}
            {formik.errors.raisedAmount && formik.touched.raisedAmount && <span className='text-danger text-xs'>{formik.errors.raisedAmount}</span>}

            </div>
        </>

    )
}

export default BetButtons
