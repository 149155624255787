import React, { useEffect, useState } from 'react'
import Dealer from "../../../../../../../assets/img/dealer.png"
import CurrentPlayer from '../../CurrentPlayer'
import { formatAddress } from '../../../../../../../Config/function'
import Fold from "../../../../../../../assets/img/fold.png"
import CardHeart from '../../CardHeart'
import CardDiamond from '../../CardDiamond'
import CardSpade from '../../CardSpade'
import CardClub from '../../CardClub'
import { useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import { useSpeechSynthesis } from 'react-speech-kit'

const PokerPlayer = ({ showResult, gameResult, gameMoveStatus, player, socket, showDealer, position }) => {
    const { speak } = useSpeechSynthesis()
    const solPrice = useSelector(state => state.auth.solPrice)
    const [playerMoveInfo, setPlayerMoveInfo] = useState(null)
    const userData = useSelector(state => state.auth.userData)

    const isMyCards = (player['address'] === userData.address)

    const foldedPlayers = gameMoveStatus?.foldedPlayers || []

    const isPlayerFold = (userId) => {
        if (gameResult) {
            const { winner, allFolded } = gameResult;
            if ((winner !== player.address) && allFolded) return true
        }
        const foldedUser = foldedPlayers.filter(item => item === userId)
        return foldedUser.length > 0
    }


    useEffect(() => {
        if (showResult) return;
        if (!socket) return;

        socket.on('userMove', (data) => {
            setPlayerMoveInfo(data)
            setTimeout(() => {
                setPlayerMoveInfo(null)
            }, 3000);
        })

        return () => {
            socket.off('userMove')
        }

    }, [])


    const RenderMove = ({ amount, action }) => {
        if (action === 'fold') {
            return <span>{action} </span>
        }
        if (action === 'check') {
            return <span>{action} </span>
        }

        if (action === 'raise') {
            return <span>{action} {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'call') {
            return <span>{action} {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'allin') {
            return <span>{action} {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'blind') {
            return <span>Blind {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'big_blind') {
            return <span>Big Blind {Number(amount).toFixed(2)} Sol</span>
        }
        if (action === 'bet') {
            return <span>Bet {Number(amount).toFixed(2)} Sol</span>
        }

        return null

    }

    const RenderDealer = () => {
        return <div>
            <img className='absolute -right-5 -bottom-5 h-8 w-8 shadow-2xl rounded-circle z-10 ' src={Dealer} alt='dealer' />
        </div>
    }
    const RenderBlind = () => {
        return <div className='absolute -top-1/4 -left-6 text-xs flex justify-center items-center text-white font-bold bg-red-800  h-8 w-auto px-2 shadow-2xl rounded-md z-10 '>
            Small Blind
        </div>
    }
    const RenderBigBlind = () => {
        return <div className='absolute -top-1/4 -left-6 text-xs flex justify-center items-center text-white font-bold bg-red-800  h-8 w-auto px-2 shadow-2xl rounded-md z-10 '>
            Big Blind
        </div>
    }

    const RenderTimer = () => {
        const [timer, setTimer] = useState(gameMoveStatus?.timer || 30)

        useEffect(() => {
            const interval = setInterval(() => {
                setTimer(timer => timer - 1)
            }, 1000)

            if (timer === 0) {
                clearInterval(interval)
            }

            return () => {
                if (timer === 0) {
                    speak({ text: 'Time Over' })
                }
                clearInterval(interval)
            }
        }, [timer])

        useEffect(() => {
            if (gameMoveStatus?.userId != player?.userId) return;
            if (timer === 0) {
                speak({ text: 'Time Over' })
            }
        }, [timer])

        if (showResult) return null

        if (!gameMoveStatus.timer) return null

        if (gameMoveStatus?.userId !== player?.userId) return null

        const calculateDashOffset = () => {
            const circumference = 2 * Math.PI * 45
            const dashOffset = circumference * (1 - timer / 30)
            return dashOffset
        }

        let color = '#55eb34'
        if (timer <= 10) {
            color = 'red'
        } else if (timer <= 20) {
            color = 'yellow'
        } 
        
        return <svg className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-36 h-36" viewBox="0 0 100 100">
        <circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke="green"
            strokeWidth="6"
        />
        {/* Progress arc */}
        <circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke={color}
            strokeWidth="6"
            strokeLinecap="round"
            strokeDasharray={2 * Math.PI * 45}
            strokeDashoffset={calculateDashOffset()}
            transform="rotate(-90 50 50)"
        />
    </svg>
    }

    const highLightCard = (card) => {
        if (!showResult) return false;
        if (!gameResult) return false;
        const { winner, winnerCombination } = gameResult;
        if (!winnerCombination) return false;
        const isExists = winnerCombination['Cards'].filter(item => item['Suit'] === card['Suit'] && item['Rank'] === card['Rank'])
        return isExists.length > 0 ? 'scale-150 mx-4' : 'bg_transparent_dark';
    }


    const RenderCard = ({ card, className }) => {
        const suit = card['Suit']
        const rank = card['Rank']

        if (suit === 'H') {
            return <div className={`${highLightCard(card)} w-16 h-20`}><CardHeart playerCard={true} rank={rank} className={className} /></div>
        }
        if (suit === 'D') {
            return <div className={`${highLightCard(card)} w-16 h-20`}><CardDiamond playerCard={true} rank={rank} className={className} /></div>
        }
        if (suit === 'S') {
            return <div className={`${highLightCard(card)} w-16 h-20`}><CardSpade playerCard={true} rank={rank} className={className} /></div>
        }
        if (suit === 'C') {
            return <div className={`${highLightCard(card)} w-16 h-20`}><CardClub playerCard={true} rank={rank} className={className} /></div>
        }

        return null
    }


    const highlightPlayer = () => {
        if (!showResult) return false;
        if (!gameResult) return false;
        const { winner, winnerCombination } = gameResult;
        if (!winner) return false;
        const isWinner = (winner === player?.address);
        return !isWinner ? 'bg_transparent_dark' : false;
    }

    let playersBalance = gameMoveStatus?.playersBalance || []
    let playerBalance = playersBalance.find(item => item.userId === player.userId)


    const RenderPlayerBalance = ({ amount }) => {
        if (!amount) return null
        return <div className='flex flex-col justify-center items-center'>
            {Number(amount).toFixed(3)} Sol
            <span className='text-xs '>({(Number(amount) * solPrice).toFixed(3)} USD)</span>
        </div>
    }



    
    if (player.fake) {
        return (<div className={`cursor-pointer hover:scale-105 absolute ${position} p-0.5 flex flex-col items-center bg-yellow-600 rounded-full border-2 border-yellow-600`}>
            <AddIcon fontSize='small' className='font-extrabold' />
        </div>)
    }


    return (
        <>
            {false && !isMyCards && <div className={`relative flex items-center bg-green-900 px-1 py-2 border-2  rounded-lg shadow-lg space-x-4 ${((gameMoveStatus?.userId === player?.userId) && (gameMoveStatus.betStatus === 'playing')) ? 'border-red-800 ' : 'border-white'} ${highlightPlayer()}`}>
                {showDealer && <RenderDealer />}
                {player?.userRole == 'blind' && <RenderBlind />}
                {player?.userRole == 'big_blind' && <RenderBigBlind />}
                {gameMoveStatus.betStatus === 'playing' && <RenderTimer />}
                <div className="relative">
                    <img src={player?.profileUrl} className="w-12 h-12 rounded-full border-white border" />
                </div>
                <div>
                    <div className="text-white font-semibold text-xs">{player.userName ? player.userName : formatAddress(player.address, 6)}</div>
                    <div className="text-blue-300 text-xs">
                        *** Sol
                        (*** USD)
                    </div>
                </div>
                {(showResult && !isPlayerFold(player?.userId)) ? <div className='w-max absolute -top-2/3 right-1/2 translate-x-1/2 flex justify-center'>
                    {player['Hand'].map((card, index) => {
                        return <RenderCard key={index} card={card} className={'w-16 h-20'} />
                    })}
                </div> : !isPlayerFold(player?.userId) && (gameMoveStatus.betStatus == 'playing') && <div className="absolute ml-auto -top-2/3 right-1/4">
                    <div className="relative w-10 h-16">
                        <div className="fade-in-up absolute top-0 left-0 w-full h-full bg-red-300 rounded-md shadow-md"></div>
                        <div className="fade-in-up   absolute top-2 left-2 w-full h-full bg-pink-300 rounded-md shadow-md"></div>
                    </div>
                </div>}
                {isPlayerFold(player?.userId) && <div className="absolute ml-auto -top-2/3 right-1/4">
                    <div className="relative w-60 h-16">
                        <div className="absolute top-0 left-1/2 w-full h-full rounded-md shadow-md">
                            {<img src={Fold} alt="card" style={{ height: '50px', }} />}
                        </div>
                    </div>
                </div>}
                {(playerMoveInfo?.userId == player?.userId) && <div class="absolute -bottom-3 px-3 left-1/2 transform -translate-x-1/2 scale-up text-capitalize capitalize text-black text-center mt-3 bg-white border border-black rounded-xl ">
                    <p class="text-black text-center font-bold text-xs"><RenderMove amount={playerMoveInfo?.amount} action={playerMoveInfo?.action} /></p>
                </div>}
            </div>}

            <div className={`flex flex-col items-center ${((gameMoveStatus?.userId === player?.userId) && (gameMoveStatus.betStatus === 'playing')) ? '' : ''}`}>

                {((gameMoveStatus?.userId === player?.userId) && (gameMoveStatus.betStatus === 'playing')) && <RenderTimer />}
                <div className="relative">
                    {isPlayerFold(player?.userId) && <div className="w-full h-10  flex items-center justify-center ">
                        <img
                            src={Fold}
                            alt="Fold"
                            className="w-full h-full rounded-full"
                        />
                    </div>}
                    {!isPlayerFold(player?.userId) && <div className="w-10 h-10 rounded-full bg-slate-700 flex items-center justify-center ">
                        <img
                            src={player.profileUrl}
                            alt="Player avatar"
                            className="w-full h-full rounded-full"
                        />
                    </div>}
                    {showDealer && (
                        <div className="absolute -top-1 -right-1 w-4 h-4 bg-yellow-400 text-black font-extrabold rounded-full  text-xs flex items-center justify-center">
                            D
                        </div>
                    )}
                    {player.userRole === 'blind' && (
                        <div className="absolute -top-5 w-20 text-center rounded-lg left-1/2 transform -translate-x-1/2 bg-red-600 text-black font-extrabold text-xs">
                            Small Blind
                        </div>
                    )}
                    {player.userRole === 'big_blind' && (
                        <div className="absolute -top-5 w-20 text-center rounded-lg left-1/2 transform -translate-x-1/2 bg-red-600 text-black font-extrabold text-xs">
                            Big Blind
                        </div>
                    )}
                </div>
                <div className="mt-1 text-xs font-medium px-1 py-1 bg-yellow-500 text-black rounded-md">
                    {((player.userId == userData.id) && playerBalance?.amount) ? <RenderPlayerBalance amount={playerBalance['amount']} /> : player.userName ? player.userName : formatAddress(player.address, 6)}
                </div>

            </div>

        </>
    )
}

export default PokerPlayer



