import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import BlurOnIcon from '@mui/icons-material/BlurOn';
import UnoCallImage from '../../../../../assets/img/games/uno_call.png'

const Player = ({ showUnoCallImage, player, index, usersRef, totalPlayers, gameStatus, foldedPlayers, leftPlayers, userBalances, playerCards, currentPlayer, timer }) => {

  const userData = useSelector(state => state.auth.userData)


  const getCardsClassName = () => {

    if (totalPlayers === 2) {
      if (index === 0) return "-top-10 -left-4 transform -translate-x-1/2"
      if (index === 1) return "-bottom-10 left-1/2 transform -translate-x-1/2"
    }
    if (totalPlayers === 3) {
      if (index === 0) return "-top-10 -left-4 transform -translate-x-1/2"
      if (index === 1) return "-right-8 rotate-90 -top-12 transform "
      if (index === 2) return "-left-8 -top-12 transform rotate-90"
    }

    if (totalPlayers === 4) {
      if (index === 0) return "-top-10 -left-4 transform -translate-x-1/2"
      if (index === 1) return "-right-8 rotate-90 -top-12 transform "
      if (index === 2) return "-bottom-10  -left-4 transform -translate-x-1/2"
      if (index === 3) return "-left-8 -top-12 transform rotate-90"
    }

    if (totalPlayers === 5) {
      if (index === 0) return "-top-10 -left-4 transform -translate-x-1/2"
      if (index === 1) return "right-16 -top-12 transform "
      if (index === 2) return "-bottom-10 right-16 transform "
      if (index === 3) return "-left-8 -bottom-10 transform "
      if (index === 4) return "-left-8 -top-10 transform "
    }
    if (totalPlayers === 6) {
      if (index === 0) return "-top-10 -left-4 transform -translate-x-1/2"
      if (index === 1) return "right-16 -top-12 transform "
      if (index === 2) return "-bottom-10 right-16 transform "
      if (index === 3) return "-bottom-10  -left-4 transform -translate-x-1/2"
      if (index === 4) return "-left-8 -bottom-10 transform "
      if (index === 5) return "-left-8 -top-10 transform "

    }
    if (totalPlayers === 7) {
      if (index === 0) return "-top-10 -left-4 transform -translate-x-1/2"
      if (index === 1) return "right-16 -top-12 transform "
      if (index === 2) return "-top-12 -right-8 transform rotate-90"
      if (index === 3) return "-bottom-10  -left-4 transform -translate-x-1/2"
      if (index === 4) return "-left-8 -bottom-10 transform "
      if (index === 5) return "-top-12 -left-8 transform rotate-90"
      if (index === 6) return "-left-8 -top-10 transform "

    }
    if (totalPlayers === 8) {
      if (index === 0) return "-top-10 -left-4 transform -translate-x-1/2"
      if (index === 1) return "-left-4 -top-12 transform "
      if (index === 2) return "-top-12 -right-8 transform rotate-90"
      if (index === 3) return "-bottom-10  -left-4 transform -translate-x-1/2"
      if (index === 4) return "-bottom-10  -left-4 transform -translate-x-1/2"
      if (index === 5) return "-left-8 -bottom-10 transform "
      if (index === 6) return "-top-12 -left-8 transform rotate-90"
      if (index === 7) return "-left-8 -top-10 transform "
    }
    if (totalPlayers === 9) {
      if (index === 0) return "-top-10 -left-4 transform -translate-x-1/2"
      if (index === 1) return "-left-4 -top-12 transform "
      if (index === 2) return "-top-12 -right-8 transform rotate-90"
      if (index === 3) return "-bottom-10  -left-4 transform -translate-x-1/2"
      if (index === 4) return "-bottom-10  -left-4 transform -translate-x-1/2"
      if (index === 5) return "-left-4 -bottom-10 transform "
      if (index === 6) return "-left-4 -bottom-10 transform "
      if (index === 7) return "-top-12 -left-8 transform rotate-90"
      if (index === 8) return "-left-4 -top-10 transform "
    }
    if (totalPlayers === 10) {
      if (index === 0) return "-top-10 -left-4 transform -translate-x-1/2"
      if (index === 1) return "-left-4 -top-12 transform "
      if (index === 2) return "-top-12 -right-8 transform rotate-90"
      if (index === 3) return "-bottom-10  -left-4 transform -translate-x-1/2"
      if (index === 4) return "-bottom-10  -left-4 transform -translate-x-1/2"
      if (index === 5) return "-left-4 -bottom-10 transform "
      if (index === 6) return "-left-4 -bottom-10 transform "
      if (index === 7) return "-top-12 -left-8 transform rotate-90"
      if (index === 8) return "-left-4 -top-10 transform "
      if (index === 9) return "-left-4 -top-10 transform "
    }
  }



  let currentPlayerCards = 0

  const playerCard = playerCards.find(playerCard => playerCard.userId === player.userId)
  if (playerCard) {
    currentPlayerCards = playerCard.cards
  }

  const playerBalance = userBalances.find(userBalance => userBalance?.userId === player.userId) || {}


  const isFolded = foldedPlayers.includes(player.userId)
  const isLeft = leftPlayers.includes(player.userId)
  const isPlayerMove = ((player?.userId == currentPlayer) && gameStatus != 'starting')


  const isTimer = timer > 0
  const RenderTimer = () => {
    const [timeLeft, setTimeLeft] = useState(timer)

    useEffect(() => {
      if (!isTimer) return;

      const interval = setInterval(() => {
        if (timeLeft === 0) {
          clearInterval(interval)
          return;
        }
        setTimeLeft(timeLeft - 1)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [timeLeft])

    if (timeLeft <= 0) return null

    if (gameStatus === 'starting') return null

    return <span className='hi-lo-player__profile absolute rounded-full h-8 w-8 -bottom-4 border-red-800 font-extrabold flex items-center justify-center bg-red-800 text-white shadow-2xl'>
      <span className='text-xs'>{timeLeft}</span>
    </span>
  }

  return (
    <>
      <div className='relative five_card_player flex items-center flex-col'>

        <div ref={el => usersRef.current[index] = el} id={`player_card_${player.userId}`} className={`flex items-center w-auto absolute ${getCardsClassName(index)}`}>

          {(!player.isFolded && !player.isLeft && (gameStatus != 'starting') && (player.userId != userData?.id)) && <div role='button' className={`hover:scale-110 text-white flex flex-col text-xs font-bold items-center justify-center bg-black border-2 shadow-2xl shadow-gray-600 rounded-lg absolute w-10 h-14 transform transition-transform duration-500 ease-in-out`}
            style={{ left: `${0 * 15}px`, zIndex: 7 }}>
            <span>+{currentPlayerCards - 1}</span>
            <span>more</span>
          </div>}
          {(!player.isFolded && !player.isLeft && (gameStatus != 'starting') && (player.userId != userData?.id)) && Array(1).fill(0).map((_, index) => (
            <div role='button' className={`hover:scale-110 text-white flex items-center justify-center bg-black border-2 shadow-2xl shadow-gray-600 rounded-lg absolute w-10 h-14 transform transition-transform duration-500 ease-in-out`}
              style={{ left: `${(index - 1) * 15}px`, zIndex: 6 - index - 1 }}>
              <BlurOnIcon fontSize='large' />
            </div>
          ))}

        </div>

        {isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-auto ${player.active && "active"}`} />}
        {!isFolded && isLeft && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile h-16 w-20 ${player.active && "active"}`} />}
        {!isFolded && !isLeft && !showUnoCallImage && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-12 w-12 border border-white ${player.active && "active"}`} />}
        {(showUnoCallImage == userData?.id) && <img src={UnoCallImage} alt='profile' className={`z-50 hi-lo-player__profile rotate-12 h-12 w-auto`} />}
        <div className={`${isPlayerMove && "py-2  px-3 bg-green-400 rounded-2xl relative"}`}>

          <div className='relative flex flex-col items-center justify-center bg-black font-extrabold px-3 text-center border-2 rounded-md'>
            {isTimer && currentPlayer === player.userId && <RenderTimer />}

            <div id={`stws_player_${player.userId}`} className='name text-white mb-1 text-xs flex flex-row'>{player.userName ? player.userName : formatAddress(player.address, 3)}
            </div>
            {player?.userId === userData?.id ? <div className='balance text-white text-xs'>{Number(playerBalance.balance).toFixed(3)} Sol</div> : null}
          </div>
        </div>





      </div>
    </>
  )
}

export default Player
