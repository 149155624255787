import React, { useEffect, useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from '../../../CommonComponent/Toast';
import axiosInstance from '../../../Config/axios';
import Select from 'react-select';



const RenderTitle = ({ item }) => {
    if (item.descriptionKey === 'DEPOSIT_BY_USER') {
        return 'Deposit by User'
    } else if (item.descriptionKey === "GAME_WINNING_PAYOUT") {
        return `Won in ${item.game}`
    } else if (item.descriptionKey === "GAME_LOSS_PAYOUT") {
        return `Lost in ${item.game}`
    } else {
        return item.descriptionKey
    }
}

const RenderAmount = ({ item }) => {
    if (item.descriptionKey === 'DEPOSIT_BY_USER') {
        return <span className='text-white font-bold'>{item.amount} Sol</span>
    } else if (item.descriptionKey === "GAME_WINNING_PAYOUT") {
        return <span className='text-green-500 font-bold'>+ {item.amount} Sol</span>
    } else if (item.descriptionKey === "GAME_LOSS_PAYOUT") {
        return <span className='text-red-500 font-bold'>- {item.amount} Sol</span>
    }  else if (item.descriptionKey === "Platform Charges") {
        return <span className='text-red-500 font-bold'>- {item.amount} Sol</span>
    }else {
        return <span className='text-white font-bold'>{item.amount} Sol</span>
    }
}

const GameTrasactionHistory = () => {


    const [userGameHistory, setUserGameHistory] = useState([])
    const [userBalance, setUserBalance] = useState(0)
    const [gameList, setGameList] = useState([])
    const [selectedGame, setSelectedGame] = useState(null)

    const fetchUserTransactionHistory = async () => {
        try {
            const payload = { gameId: selectedGame?.value }
            const response = await axiosInstance.post('/user/transaction-history', payload)

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeButton: false, theme: 'dark'
                })
            } else {
                setUserGameHistory(responseData.data)
            }

        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeButton: false, theme: 'dark'
            })
        }
    }

    const fetchUserBalance = async () => {
        try {
            const response = await axiosInstance.get('/user/balance')
            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeButton: false, theme: 'dark'
                })
            } else {
                setUserBalance(responseData.data)
            }
        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeButton: false, theme: 'dark'
            })
        }
    }

    const fetchGameList = async () => {
        try {
            const response = await axiosInstance.post('/api/game/list')
            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeButton: false, theme: 'dark'
                })
            } else {
                setGameList(responseData.data?.data)
            }
        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeButton: false, theme: 'dark'
            })
        }

    }

    useEffect(() => {
        fetchUserBalance()
        fetchGameList()
    }, [])


    useEffect(() => {
        fetchUserTransactionHistory()

    }, [selectedGame])

    const gameListOptions = gameList.map(item => {
        return {
            value: item.id,
            label: item.title
        }
    })


    return (
        <div className="px-4 py-6 sm:px-6 lg:px-8 xl:px-10">

            <div className="bg-gray-900 p-6 rounded-lg shadow-lg border-4 border-gray-700">
                <div className="flex justify-between items-center">
                    <h2 className="text-white text-lg mb-6 font-bold">
                        Balance: {userBalance} Sol
                    </h2>
                    <div className='flex items-center justify-center'>
                        {/* <Select options={gameListOptions}
                            placeholder='Select Game'
                            className='w-72 me-5'
                            value={selectedGame}
                            isClearable
                            onChange={(selectedOption) => setSelectedGame(selectedOption)}
                        /> */}
                        <button className="text-white bg-purple-700 hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900">
                            Export <DownloadIcon className='ms-3' />
                        </button>
                    </div>
                </div>
                <hr className="border border-gray-700 mt-3 mb-4" />
                <div class="relative overflow-x-auto overflow-y-scroll withdraw_history_table" style={{
                    height: '600px'
                }}>
                    <table class="w-full text-sm text-left  rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    Title
                                </th>
                                {/* <th scope="col" class="px-6 py-3">
                                    Status
                                </th> */}
                                <th scope="col" class="px-6 py-3">
                                    Amount
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    DateTime
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userGameHistory.map((history, index) => (<tr class="border-b  text-white dark:bg-gray-800 dark:border-gray-700">
                                    <th scope="row" class="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white">
                                        <RenderTitle item={history} />
                                    </th>

                                    <td class="px-6 py-4">
                                        <RenderAmount item={history} />
                                    </td>
                                    <td class="px-6 py-4">
                                        {moment(history.createdAt).format('YYYY-MM-DD hh:mm A')}
                                    </td>
                                </tr>))
                            }

                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default GameTrasactionHistory
