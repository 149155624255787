import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import { useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add';
import SolToUSD from '../../../../../CommonComponent/SolToUSD'

const Player = ({ player, position }) => {
    const userData = useSelector(state => state.auth.userData)


  if (player.fake) {
    return (<div className={`cursor-pointer hover:scale-105 p-2 flex flex-col items-center bg-red-600 rounded-full border-2 border-red-600 text-white`}>
      <AddIcon fontSize='small' className='font-extrabold' />
    </div>)
  }


    return (
        <div className='hi-lo-player flex items-center flex-col	'>
            {<img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-10 w-10 border border-white p-1/2 ${player.active && "active"}`} />}
            <div className='flex flex-col items-center justify-center bg-red-600 font-extrabold px-3 text-center border-2 rounded-md'>
                {player?.userId === userData?.id ? <div className='balance flex flex-col bg-red-600 text-white items-center justify-center  font-extrabold p-0.5 text-xs w-max'>{Number(player.amount).toFixed(3)} Sol 
                    <span>(<SolToUSD amount={Number(player.amount)} symbol={"USD"}/>)</span>
                </div> : <div className='balance bg-red-600 text-white  text-xs'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>}
            </div>
        </div>
    )
}

export default Player
