import Allgamepage from "../Component/Page/Allgamepage";
import Proparties from "../Component/Page/Proparties";
import PlayGame from "../Component/Page/PlayGamePage"
import GamePlay from "../Component/Page/GamePlay/index"
import PokerTest from "../Component/Page/GamePlay/PokerTest/index"
import Deposit from "../Component/Page/Deposit/index"
// import GamePlay from "../Component/Page/GamePlay"
import FavoritesList from "../Component/Page/FavoritesList"
import Wallet from "../Component/Page/Wallet"
import Withdrawal from "../Component/Page/Withdrawal"
import GameTrasactionHistory from "../Component/Page/GameTrasactionHistory";
import ReferralEarning from "../Component/Page/ReferralEarning"
import UserProfile from '../Component/Page/UserProfile'
import Setting from "../Component/Page/Setting"
import GameViewPage from "../Component/Page/GameViewPage";
import Leaderboard from "../Component/Page/LeaderBoard";
import PokerTournamentPage from "../Component/Page/PokerTourments";
import FutureProject from '../Component/Page/Futureproject'
import GameRules from '../Component/Page/GameRules'
import Support from "../Component/Page/Support";

const routes = [
  {
    id: 1,
    path: "/all-game-page",
    hideNavbar: false,
    element: <Allgamepage />,
  },
  {
    id: 2,
    path: "/proparties",
    hideNavbar: false,
    element: <Proparties />,
  },
  {
    id: 3,
    path: "/play",
    exact: true,
    hideNavbar: false,
    element: <PlayGame />,
  },
  {
    id: 4,
    path: "/play/:slug/:gameAddress",
    hideSidebar: true,
    hideNavbar: true,
    exact: true,
    element: <GamePlay />,
  },
  {
    id: 5,
    path: "/play/poker",
    hideSidebar: true,
    exact: true,
    hideNavbar: false,
    element: <PokerTest />,
  },
  {
    id: 5,
    path: "/favorites-list",
    hideNavbar: false,
    element: <FavoritesList />,
  },
  {
    id: 6,
    path: "/deposit",
    hideNavbar: false,
    element: <Deposit />,
  },
  {
    id: 7,
    path: "/wallet",
    element: <Wallet />,
  },
  {
    id: 7,
    path: "/withdrawal",
    hideNavbar: false,
    element: <Withdrawal />,
  },
  {
    id: 8,
    path: "/referralearning",
    hideNavbar: false,
    element: <ReferralEarning />,
  },
  {
    id: 9,
    path: "/transaction",
    hideNavbar: false,
    element: <GameTrasactionHistory />,
  },
  {
    id: 10,
    path: "/profile",
    hideNavbar: false,
    element: <UserProfile />,
  },
  {
    id: 11,
    path: "/setting",
    element: <Setting />,
  },
  {
    id: 12,
    path: "/game-view/:slug",
    hideNavbar: false,
    element: <GameViewPage />,
  },
  {
    id: 13,
    path: "/leader-board",
    element: <Leaderboard />,
  },
  {
    id: 14,
    path: "/poker-tournament",
    element: <PokerTournamentPage />,
  },
  {
    id: 15,
    path: "/future-project",
    element: <FutureProject />,
  },
  {
    id: 16,
    path: "/game-rules",
    element: <GameRules />,
  },
  {
    id: 17,
    path: "/support",
    element: <Support />,
  },
  
];
export default routes;
