import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import { toast, Slide } from 'react-toastify'
import axiosInstance from '../../../Config/axios'
const Support = () => {

    const formik = useFormik({
        initialValues: {
            email: '',
            message: ''
        },
        validationSchema: yup.object({
            email: yup.string().email('Invalid email address').required('Email is required'),
            message: yup.string().required('Message is required').min(10, 'Message must be at least 10 characters')
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            handleMessageSubmit(values, setSubmitting, resetForm)
        }
    })

    const handleMessageSubmit = async (values, setSubmitting, resetForm) => {
        try {

            const response = await axiosInstance.post('/message/send', values)

            const { data: responseData } = response;
            if (!responseData.status) {
                toast.error(responseData.message, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark'
                })
                setSubmitting(false);
                return;
            }

            toast.success('Message sent successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            })
            resetForm();
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
            toast.error('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark'
            })
        }
    }


    return (
        <div className='bg-gray-900 m-3 p-3 rounded-lg text-white'>
            <h1 className='text-2xl font-bold'>Support</h1>
            <form className='flex flex-col justify-center items-start mt-5' onSubmit={formik.handleSubmit}>

                <div className='flex flex-col justify-center items-start w-full mb-4'>
                    <label htmlFor='email' className='text-lg font-bold'>Email</label>
                    <input type='email' className='w-full p-2 rounded-lg bg-gray-800 text-white' placeholder='Enter your email here...' name='email' onChange={formik.handleChange} value={formik.values.email} />
                    {formik.errors.email && <p className='text-red-500'>{formik.errors.email}</p>}
                </div>

                <div className='flex flex-col justify-center items-start w-full mb-3'>
                    <label htmlFor='message' className='text-lg font-bold'>Message</label>
                    <textarea className='w-full h-40 p-2 rounded-lg bg-gray-800 text-white' placeholder='Enter your message here...' name='message' onChange={formik.handleChange} value={formik.values.message}></textarea>
                    {formik.errors.message && <p className='text-red-500'>{formik.errors.message}</p>}
                </div>

                <button disabled={formik.isSubmitting} className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none py-2.5 text-black font-bold p-2 px-5 rounded-lg mt-3'>{
                    formik.isSubmitting ? 'Sending...' : 'Send'
            }</button>
            </form>
        </div>
    )
}

export default Support