import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import { useSelector } from 'react-redux';
import SolToUSD from '../../../../../CommonComponent/SolToUSD';
import AddIcon from '@mui/icons-material/Add';

const Player = ({ player, position }) => {

    const userData = useSelector((state) => state.auth.userData);
    console.log(player)

    if (player.fake) {
        return (<div className={`cursor-pointer hover:scale-105 absolute ${position} p-0.5 flex flex-col items-center bg-yellow-600 rounded-full border-2 border-yellow-600`}>
            <AddIcon fontSize='small' className='font-extrabold' />
        </div>)
    }

    return (
        <>
            {false && <div className={`relative flex items-center bg-green-900 px-2 py-1 border-2  rounded-lg shadow-lg space-x-4`}>

                <div className="relative">
                    <img src={player?.profileUrl} className="w-12 h-12 rounded-full border-white border" />
                </div>

                <div className='flex flex-col'>
                    <div className="text-white font-semibold text-xs">
                        {player.userName ? player.userName : userData?.id === player.userId ? 'You' : formatAddress(player.address, 6)}
                    </div>

                    <div className="text-white text-xs font-bold">
                        {userData?.id === player.userId ? Number(player?.amount).toFixed(2) : '***'} Sol
                        {userData?.id === player.userId ?
                            <span className='ml-1'>
                                (<SolToUSD amount={player?.amount} symbol={"USD"} />)
                            </span>

                            : "(*** USD)"}

                    </div>
                </div>


            </div>}


            <div className={`flex flex-col items-center`}>
                <div className="relative">
                    <div className="w-10 h-10 rounded-full bg-slate-700 flex items-center justify-center ">
                        <img
                            src={player.profileUrl}
                            alt="Player avatar"
                            className="w-full h-full rounded-full"
                        />
                    </div>
                </div>
                <div className="mt-1 text-xs font-medium px-1 py-1 bg-yellow-500 text-black rounded-md flex items-center flex-col">
                    {player.userName ? player.userName : formatAddress(player.address, 6)}
                    {player.userId === userData?.id && <span className='text-xs text-black '>
                        {Number(player.restAmount).toFixed(3)} Sol
                    </span>}
                </div>

            </div>
        </>
    )
}

export default Player