import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import Card from "../../PokerTest/Components/Pages/Cards/index"
import { useSelector } from 'react-redux'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AddIcon from '@mui/icons-material/Add';

const Player = ({ player, gameResult, position }) => {
  const userData = useSelector(state => state.auth.userData)

  const highLightCard = (card) => {
    if (!gameResult) return false;
    if (gameResult?.isAllIn) return true
    return true

  }


  const RenderGameAmount = ({ amount, type }) => {

    let newAmount = Number(amount).toFixed(3)
    if (type === 'withdrawal') {
      return <span className="text-red-600 text-xs font-extrabold"> - {newAmount} Sol</span>
    } else if (type === 'deposit') {
      let playerAmount = Number(player.amount).toFixed(3)
      let restAmount = Number(player.restAmount).toFixed(3)

      let totalAmount = Number(restAmount) - Number(playerAmount)
      totalAmount = totalAmount.toFixed(3)
      totalAmount = Number(totalAmount)
      
      totalAmount = Math.abs(totalAmount).toFixed(2)

      let winnerTotalAmount = Number(totalAmount) + Number(newAmount)
      winnerTotalAmount = winnerTotalAmount.toFixed(3)

      if (player.userId == userData?.id) return <span className="text-green-500 text-xs font-extrabold"> ({winnerTotalAmount}) Sol </span>
      
      return <span className="text-green-500 text-xs font-extrabold"> + {newAmount} Sol </span>

    }
  }

  const RenderWinningHands = ({ player }) => {

    if (!gameResult) return null;
    if (player.isFolded) return null
    if (player.isLeft) return null
    const { winningHands } = gameResult;
    if (!winningHands) return null;
    const playerRank = winningHands.find(item => item['userId'] === player?.userId)
   
    return <span className="text-xs font-semibold px-2 py-1 uppercase">{playerRank['rank']} {playerRank['hand']}</span>
  }


  if (player.fake) {
    return (<div className={`cursor-pointer hover:scale-105 absolute ${position} p-0.5 flex flex-col items-center bg-yellow-600 rounded-full border-2 border-yellow-600`}>
        <AddIcon fontSize='small' className='font-extrabold' />
    </div>)
}


  return (
    <div className={`relative flex items-center rounded-lg space-x-4 border-white `}>
      {/*  */}
      <div className='relative flex flex-col items-center '>
        <div className='w-full justify-center  flex items-center z-20 gap-1'>
          {!player.isFolded && !player.isLeft && player['Hand'].map((card, index) => {
            return <Card key={index} card={card} className={`w-10 h-14 bg-white rounded-sm ${!gameResult?.isAllIn ? highLightCard(card) ? '' : '' : ''}`} />
          })}
          {player.isFolded && !player.isLeft && <img src={FoldImage} className='block  mx-auto w-full h-10' />}
          {player.isLeft && !player.isFolded && <img src={LeftImage} className='block  mx-auto w-full h-10' />}
        </div>

        <div className={`p-0 ${player.index == 1 && 'bg-green-300 rounded-md' } rounded-md"}`}>
        <div className={`flex flex-col items-center`}>
                <div className="relative">
                   
                </div>
                <div id={`poker_result_player_${player.userId}`} className="mt-1 text-xs font-medium px-1 py-1 bg-black text-white rounded-md">
                    {player.userName ? player.userName : formatAddress(player.address, 6)}
                    <RenderGameAmount amount={player.totalAmount} type={player.type} />
                </div>

            </div>
          <div className='w-full bg-purple-600 text-white px-2 flex items-center justify-center'>
            <span className='font-extrabold italic text-2xl'>{player.index} {player.index == 1 && <EmojiEventsIcon />}</span>
            <span className='text-xs font-semibold ml-1'>{player.rank}</span>
            <RenderWinningHands player={player} />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Player
